.wrapper-skills {
    color: white;
}

.skills {
    text-align: center;
}

.skills span {
    font-size: 2em;
    font-weight: 600;
}

.skills-container {
    max-width: 1000px;
    margin-top: 25px;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    gap: 20px 10px;
    justify-items: center;
}

.skill-item {
    background: rgba(37, 37, 37, 0.84);
    font-size: 30px;
    text-align: center;
    border-radius: 12px;
    width: 45px;
    height: 45px;
}

.skill-item img {
    width: 100%;
    height: 100%;
}

.skill-name {
    font-size: 20px;
    padding-top: 10px;
}