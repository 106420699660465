.wrapper-contact-me {
    text-align: center;
}

.links {
    width: 28px;
    font-size: 28px;
    color: rgb(93, 188, 255);
    padding-left: 20px;
}

.links i {
    width: 28px;
    font-size: 28px;
}