* {
	/* background-color: rgb(16, 16, 16); */
	background-color: rgba(7 17 22);
	font-family: "Inter", sans-serif;
}

/* color: rgb(0, 127, 255); */
/* color: rgb(32, 118, 205); */

.home {
	max-width: 1000px;
	margin: 0 auto;
	padding: 0px 15px 0px 15px;
}

.heading {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin: 50px 0px 50px 0px;
}

.heading-left {
	display: inline-block;
	padding: 0px 10px 0px 10px;
}


.heading-left img {
	width: 250px;
	height: 250px;
	border-radius: 50%;
	padding: 5px;
	border: 2px solid rgb(0, 127, 255);
}

.heading-right {
	margin-left: 0px;
	margin: auto;
}

.title {
	font-size: 40px;
	color: white;
	font-weight: 400;
	line-height: 60px;
}

.hi {
	font-size: 30px;
	color: white;
}

.wave {
	font-size: 33px;
	animation-name: wave-animation;
	animation-duration: 2.5s;
	animation-iteration-count: infinite;
	transform-origin: 70% 70%;
	display: inline-block;
}

@keyframes wave-animation {
	0% {
		transform: rotate(0.0deg)
	}

	10% {
		transform: rotate(14.0deg)
	}

	20% {
		transform: rotate(-8.0deg)
	}

	30% {
		transform: rotate(14.0deg)
	}

	40% {
		transform: rotate(-4.0deg)
	}

	50% {
		transform: rotate(10.0deg)
	}

	60% {
		transform: rotate(0.0deg)
	}

	100% {
		transform: rotate(0.0deg)
	}
}

.name {
	color: rgb(244, 80, 143);
	font-size: 40px;
	font-weight: 500;
}

.subtitle {
	color: rgb(93, 188, 255);
	font-size: 40px;
	font-weight: 500;
}

.about {
	margin-bottom: 50px;
	font-size: 22px;
}

.about p {
	margin: 0px;
	padding: 0px 10px;
	color: white;
	line-height: 35px;
	margin-bottom: 20px;
}

.highlight {
	color: #007bff;
}

.unique {
	color: rgb(244, 80, 143);
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.line {
	width: 100%;
	max-width: 1000px;
	height: 4px;
	margin: 5vh auto;
	background: linear-gradient(to right, rgb(244, 80, 143), #007bff);
	border: none;
}