.footer {
    margin: 5vh auto;
    color: white;
}

.footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer-left {
    color: white;
    font-size: normal;
}