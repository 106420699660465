.logo-large {
    color: white;
}

.logo-small {
    color: white;
}

.logo-text {
    font-size: 40px !important;
    padding: 0px 10px;
}