.header {
    font-size: 28px;
    font-weight: 500;
    color: white;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    flex-wrap: wrap;
}

.nav-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-left span {
    font-size: 28px;
    color: white;
}

.nav-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-right a {
    text-decoration: none;
    color: white;
}

.nav-right a:hover {
    text-decoration: underline;
}

.resume {
    /* Animation */
    animation: fadeIn;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-play-state: running;
    animation-duration: 2.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0.1;
    }

    20% {
        opacity: 0.2;
    }

    30% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.5;
    }

    60% {
        opacity: 0.6;
    }

    70% {
        opacity: 0.7;
    }

    80% {
        opacity: 0.8;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
    }
}